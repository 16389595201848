import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const Home = () => {
  return (
    <Container maxWidth="sm">
      <Box textAlign="center" mt={5}>
        <Typography variant="h4" gutterBottom>
          Crypto Payment Gateway'e Hoşgeldiniz
        </Typography>
        <Typography variant="body1" gutterBottom>
          Bu platform üzerinden kripto para ödemeleri alabilir ve yönetebilirsiniz. 
        </Typography>
      </Box>
    </Container>
  );
};

export default Home;
