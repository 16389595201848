import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, Avatar, Menu, MenuItem, IconButton, Drawer, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import Switch from '@mui/material/Switch';
import { FaBars, FaHome, FaUserShield, FaMoneyBillWave, FaSignOutAlt } from 'react-icons/fa'; // Updated Icons
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import API from '../services/api';

// Custom Switch Styling
const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 57,
  height: 29,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#1a9f93',
    width: 27,
    height: 27,
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#aab4be',
    borderRadius: 20 / 2,
  },
}));

const Navbar = ({ mode, toggleMode }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const open = Boolean(anchorEl);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Media query for mobile responsiveness

  // Fetch user data from localStorage or API
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        setUserData(user);
      } else {
        API.get('/user/profile', {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(response => {
          setUserData(response.data);
        })
        .catch(error => {
          console.error('Error fetching user profile:', error);
        });
      }
    }
  }, []);

  // Menu handling functions
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('token');
      await API.post('/logout', {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  // Drawer content
  const drawerItems = (
    <List>
      <ListItem button component={Link} to="/" onClick={toggleDrawer(false)}>
        <ListItemIcon><FaHome size={20} color={theme.palette.primary.main} /></ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>
      <ListItem button component={Link} to="/admin" onClick={toggleDrawer(false)}>
        <ListItemIcon><FaUserShield size={20} color={theme.palette.primary.main} /></ListItemIcon>
        <ListItemText primary="Admin" />
      </ListItem>
      <ListItem button component={Link} to="/payment" onClick={toggleDrawer(false)}>
        <ListItemIcon><FaMoneyBillWave size={20} color={theme.palette.primary.main} /></ListItemIcon>
        <ListItemText primary="Payment" />
      </ListItem>
      {userData && (
        <>
          <ListItem>
            <Avatar alt={userData.username} src="/profil.png" sx={{ marginRight: 2 }} />
            <ListItemText primary={userData.username} secondary={userData.email} />
          </ListItem>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon><FaSignOutAlt size={20} color={theme.palette.error.main} /></ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </>
      )}
    </List>
  );

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Crypto Payment Gateway
        </Typography>

        {isMobile ? (
          <>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
              <FaBars size={24} />
            </IconButton>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
              {drawerItems}
            </Drawer>
          </>
        ) : (
          <>
            <Button color="inherit" component={Link} to="/"><FaHome size={15} color={theme.palette.secondary.main} style={{ marginRight: 5 }} />Home</Button>
            <Button color="inherit" component={Link} to="/admin"><FaUserShield size={15} color={theme.palette.secondary.main} style={{ marginRight: 5 }} />Admin</Button>
            <Button color="inherit" component={Link} to="/payment"><FaMoneyBillWave size={15} color={theme.palette.secondary.main} style={{ marginRight: 5 }} />Payment</Button>

            {userData && (
              <>
                <IconButton onClick={handleMenuClick}>
                  <Avatar alt={userData.username} src="/profil.png" />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleMenuClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                      bgcolor: mode === 'dark' ? '#fff' : '#019587',
                      color: mode === 'dark' ? '#000' : '#fff',
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem>
                    <Avatar alt={userData.username} src="/profil.png" />
                    <Typography sx={{ ml: 2, color: mode === 'dark' ? '#000' : '#fff' }}>
                      {userData.username}
                      <br />
                      <Typography variant="body2" component="span">
                        {userData.email}
                      </Typography>
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
                </Menu>
              </>
            )}

            <MaterialUISwitch
              checked={mode === 'dark'}
              onChange={toggleMode}
            />
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
