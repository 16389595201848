import React, { createContext, useContext, useState, useCallback } from 'react';
import { Snackbar, Alert } from '@mui/material';

// Bildirimleri yönetmek için bir Context oluşturuyoruz
const NotificationContext = createContext();

export const useNotifications = () => {
  return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState({
    message: '',
    type: 'info',  // 'success', 'error', 'warning', 'info'
    open: false,
  });

  const showNotification = useCallback((message, type = 'info') => {
    setNotification({ message, type, open: true });
  }, []);

  const closeNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  return (
    <NotificationContext.Provider value={showNotification}>
      {children}
      <Snackbar
        open={notification.open}
        autoHideDuration={5000}
        onClose={closeNotification}
      >
        <Alert severity={notification.type} onClose={closeNotification}>
          {notification.message}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
};
