import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Typography, Box, Avatar, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import API from '../services/api'; // API servisleri için doğru yolu ayarlayın.
import TurkishFlag from '../assets/turkey.png'; // TurkishFlag yolunu kontrol edin.

const Payment = ({ user }) => {
  const [paymentAmount, setPaymentAmount] = useState('');
  const [balance, setBalance] = useState(null);
  const [recipientName, setRecipientName] = useState('');
  const [iban, setIban] = useState('');
  const [referenceId, setReferenceId] = useState('');
  const [transfers, setTransfers] = useState([]);

  useEffect(() => {
    const fetchUserBalance = async () => {
      try {
        const response = await API.get(`/users/${user.id}/balance`);
        setBalance(response.data.balance !== null ? parseFloat(response.data.balance) : 0);
      } catch (error) {
        console.error("Bakiye alınırken hata oluştu:", error);
      }
    };
    const fetchUserTransfers = async () => {
      try {
        const response = await API.post(`/users/${user.id}/transfer`, {
          recipientName,
          iban,
          referenceId,
          paymentAmount,
        });
        setTransfers(response.data);  // Gelen veriyi state'e kaydet
      } catch (error) {
        console.error('Transferler alınırken hata oluştu:', error.response ? error.response.data : error.message);
      }
    };

    fetchUserBalance();
    fetchUserTransfers();
  }, [user.id]);

  const handlePayment = async () => {
    const validAmount = parseFloat(paymentAmount);
    if (validAmount > 0 && validAmount <= balance && recipientName && iban && referenceId) {
      try {
        // Bakiyeyi güncelle
        await API.post(`/users/${user.id}/payment`, { amount: validAmount });
  
        // Transfer işlemini kaydet
        await API.post(`/users/${user.id}/transfer`, { recipientName, iban, referenceId, paymentAmount });
  
        setBalance((prevBalance) => prevBalance - validAmount);
        alert(`Ödeme başarıyla yapıldı!`);
      } catch (error) {
        console.error('Ödeme sırasında hata oluştu:', error);
        alert('Ödeme sırasında bir hata oluştu.');
      }
    } else {
      alert('Lütfen tüm bilgileri eksiksiz doldurun.');
    }
  };
  
  

  return (
    <Container maxWidth="xs" sx={{ mt: 5 }}>
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          p: 4,
          backgroundColor: 'white',
          borderRadius: '12px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography variant="h4" gutterBottom>
          Para Transfer
        </Typography>

        {balance !== null && !isNaN(balance) ? (
  <Typography variant="subtitle1" color='secondary' gutterBottom>
    <b>Bakiye: {parseFloat(balance).toFixed(2)} TRY</b>
  </Typography>
) : (
  <Typography variant="subtitle1" color='secondary' gutterBottom>
    <b>Bakiye: Bilinmiyor</b>
  </Typography>
)}


        <TextField
          fullWidth
          label="Alıcı Adı Soyadı"
          value={recipientName}
          onChange={(e) => setRecipientName(e.target.value)}
          variant="outlined"
        />

        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <TextField
            fullWidth
            label="Gönderilecek Tutar"
            value={paymentAmount}
            onChange={(e) => setPaymentAmount(e.target.value)}
            variant="outlined"
            type="number"
          />
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
            <Avatar src={TurkishFlag} alt="TRY" sx={{ width: 24, height: 24 }} />
            <Typography sx={{ ml: 1, fontSize: '1.2rem', fontWeight: 500 }}>TRY</Typography>
          </Box>
        </Box>

        <TextField
          fullWidth
          label="IBAN"
          value={iban}
          onChange={(e) => setIban(e.target.value)}
          variant="outlined"
        />

        <TextField
          fullWidth
          label="Referans ID"
          value={referenceId}
          onChange={(e) => setReferenceId(e.target.value)}
          variant="outlined"
        />

        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          onClick={handlePayment}
          disabled={
            !paymentAmount || !recipientName || !iban || !referenceId || parseFloat(paymentAmount) > balance || balance === null
          }
          sx={{ mt: 2, borderRadius: 2 }}
        >
          Devam Et
        </Button>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Son Transferler
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Alıcı</TableCell>
              <TableCell>IBAN</TableCell>
              <TableCell>Referans ID</TableCell>
              <TableCell>Tutar</TableCell>
              <TableCell>Tarih</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
  {transfers && transfers.length > 0 ? (
    transfers.map((transfer) => (
      transfer && (
        <TableRow key={transfer.id}>
          <TableCell>{transfer.recipient_name || "Bilinmiyor"}</TableCell>
          <TableCell>{transfer.iban || "Bilinmiyor"}</TableCell>
          <TableCell>{transfer.reference_id || "Bilinmiyor"}</TableCell>
          <TableCell>{transfer.amount ? `${transfer.amount} TRY` : "Bilinmiyor"}</TableCell>
          <TableCell>{transfer.created_at ? new Date(transfer.created_at).toLocaleDateString() : "Bilinmiyor"}</TableCell>
        </TableRow>
      )
    ))
  ) : (
    <TableRow>
      <TableCell colSpan={5} align="center">Hiçbir transfer bulunamadı.</TableCell>
    </TableRow>
  )}
</TableBody>

        </Table>
      </Box>
    </Container>
  );
};

export default Payment;
