import React, { useState } from 'react';
import { TextField, Button, Container, Paper, Typography, Grid, Box, Checkbox, FormControlLabel } from '@mui/material';
import API from '../services/api';  
import { useNavigate } from 'react-router-dom';
import { useNotifications } from '../components/NotificationProvider';  

const Login = ({ setUser }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error] = useState(null);  
  const navigate = useNavigate();
  const showNotification = useNotifications(); 

  const handleLogin = async () => {
    try {
      const response = await API.post('/login', { email, password });
      const { token, user } = response.data;
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));

      setUser(response.data.user); 
      navigate('/');
    } catch (error) {
      console.error('Login error:', error);
      showNotification('Giriş hatalı. Lütfen bilgilerinizi kontrol edin.');
    }
  };

  return (
    <Container maxWidth="xs">
      <Paper elevation={3} sx={{ mt: 8, p: 4, borderRadius: '10px' }}>
        {/* Logo veya İkon alanı */}
        <Box display="flex" justifyContent="center" mb={2}>
          <img 
            src="/logo192.png"  // Burada logonuzun URL'sini ekleyebilirsiniz
            alt="logo"
            style={{ width: 85, height: 85 }} 
          />
        </Box>

        <Typography variant="h5" align="center" gutterBottom>
          Welcome back
        </Typography>
        <Typography variant="body2" align="center" color="textSecondary" gutterBottom>
          Please enter your details to login.
        </Typography>

        {/* Giriş Formu */}
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email Address"
              variant="outlined"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              sx={{ borderRadius: '10px' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Password"
              variant="outlined"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              sx={{ borderRadius: '10px' }}
            />
          </Grid>

          {/* Remember Me Checkbox */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                  color="primary"
                />
              }
              label="Remember me"
            />
          </Grid>

          {error && (
            <Grid item xs={12}>
              <Typography color="error" align="center">
                {error}
              </Typography>
            </Grid>
          )}

          {/* Giriş Butonu */}
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleLogin}
              sx={{
                py: 1.5,
                borderRadius: '10px',
              }}
            >
              Login
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default Login;
