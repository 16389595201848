import React, { useEffect, useState } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, MenuItem,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Card,
} from '@mui/material';
import { useTheme } from '@mui/material/styles'; // Tema yönetimi
import API from '../services/api';
import { useNotifications } from '../components/NotificationProvider';
import { red, green } from '@mui/material/colors';

const Admin = () => {
  const theme = useTheme(); // Tema yönetimi için gerekli hook
  const showNotification = useNotifications();
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ username: '', email: '', password: '', role: 'user' });
  const [selectedUser, setSelectedUser] = useState(null);
  const [balance, setBalance] = useState(0);
  const [action, setAction] = useState('add');
  const [showPopup, setShowPopup] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [error, setError] = useState(null);
  const token = localStorage.getItem('token');
  const [currency, setCurrency] = useState('TRY');


  
  // Kullanıcıları API'den çekmek için useEffect
  useEffect(() => {
    if (!token) {
      setError('Token eksik. Lütfen giriş yapınız.', 'error');
      return;
    }

    const fetchUsers = async () => {
      try {
        const response = await API.get('/users', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        const sortedUsers = response.data.sort((a, b) => a.id - b.id);
        setUsers(sortedUsers);
      } catch (error) {
        console.error('Kullanıcıları getirme hatası:', error);
        setError('Kullanıcıları getirme hatası.', 'error');
      }
    };

    fetchUsers();
  }, [token]);
  

  const handleBalanceUpdate = async () => {
    try {
      const operation = action === 'add' ? 'addBalance' : 'subtractBalance';
      
      // Ensure you're passing the correct currency to the backend
      await API.post(`/${operation}`, { 
        userId: selectedUser.id, 
        balance, 
        currency 
      }, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
  
      showNotification('İşlem başarıyla gerçekleştirildi!', 'success');
      setShowPopup(false);
      setBalance(0);
  
      // Fetch updated user data
      const response = await API.get('/users', {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Bakiye işlemi hatası:', error);
      showNotification('Bakiye işlemi hatası: ' + (error.response?.data?.message || 'Bilinmeyen hata'), 'error');
    }
  };
  
  

  // Kullanıcı ekleme işlemi
  const handleAddUser = async () => {
    if (!newUser.username || !newUser.email || !newUser.password) {
      showNotification('Tüm alanları doldurmalısınız.', 'error');
      return;
    }
    try {
      const response = await API.post('/addUser', newUser, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      setUsers(prevUsers => [...prevUsers, response.data].sort((a, b) => a.id - b.id));
      setNewUser({ username: '', email: '', password: '', role: 'user' });
      setError(null);
      showNotification('Kullanıcı başarıyla eklendi!', 'success');
    } catch (error) {
      console.error('Kullanıcı ekleme hatası:', error);
      showNotification('Kullanıcı ekleme hatası: ' + (error.response?.data?.message || 'Bilinmeyen hata'), 'error');
    }
  };

  // Kullanıcı güncelleme işlemi
  const handleUpdateUser = async () => {
    try {
      const updateData = {
        email: selectedUser.email,
        username: selectedUser.username,
        role: selectedUser.role,
      };
      if (selectedUser.password && selectedUser.password.length > 0) {
        updateData.password = selectedUser.password;
      }
      const response = await API.put(`/users/${selectedUser.id}`, updateData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUsers(prevUsers => prevUsers.map(user => (user.id === selectedUser.id ? response.data : user)));
      showNotification('Kullanıcı başarıyla güncellendi!', 'success');
      setShowUpdatePopup(false);
    } catch (error) {
      console.error('Kullanıcı güncelleme hatası:', error);
      showNotification('Kullanıcı güncelleme hatası: ' + (error.response?.data?.message || 'Bilinmeyen hata'), 'error');
    }
  };

  return (
    <Box sx={{ padding: '20px', maxWidth: '1000px', margin: '0 auto' }}>
      <Card sx={{ p: 4, mb: 4, boxShadow: 3, backgroundColor: theme.palette.background.paper, borderRadius: 4 }}>
        <Typography variant="h4" align="center" color={theme.palette.primary.main} gutterBottom>
          Admin Paneli
        </Typography>

        {error && <Typography variant="body1" color="error">{error}</Typography>}

        <Typography variant="h5" gutterBottom mt={4} color={theme.palette.primary.main}>
          Kullanıcı Ekle
        </Typography>

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="Kullanıcı Adı"
              size="small"
              value={newUser.username}
              onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="Email"
              size="small"
              value={newUser.email}
              onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="Şifre"
              type="password"
              size="small"
              value={newUser.password}
              onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              select
              fullWidth
              label="Rol"
              size="small"
              value={newUser.role}
              onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
            >
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={2}>
            <Button variant="contained" color="primary" fullWidth onClick={handleAddUser}>
              Kullanıcı Ekle
            </Button>
          </Grid>
        </Grid>
      </Card>

      <TableContainer component={Paper} sx={{ mb: 4, boxShadow: 3, borderRadius: 2 }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow style={{ backgroundColor: theme.palette.primary.main }}>
              <TableCell style={{ color: theme.palette.primary.contrastText }}><strong>ID</strong></TableCell>
              <TableCell style={{ color: theme.palette.primary.contrastText }}><strong>Kullanıcı Adı</strong></TableCell>
              <TableCell style={{ color: theme.palette.primary.contrastText }}><strong>Email</strong></TableCell>
              <TableCell style={{ color: theme.palette.primary.contrastText }}><strong>Rol</strong></TableCell>
              <TableCell style={{ color: theme.palette.primary.contrastText }}><strong>Bakiye</strong></TableCell>
              <TableCell style={{ color: theme.palette.primary.contrastText }}><strong>Bakiye</strong></TableCell>
              <TableCell style={{ color: theme.palette.primary.contrastText }}><strong>Bakiye</strong></TableCell>
              <TableCell style={{ color: theme.palette.primary.contrastText }}><strong>Bakiye</strong></TableCell>
              <TableCell style={{ color: theme.palette.primary.contrastText }} align="center"><strong>İşlemler</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, index) => (
              <TableRow key={user.id} style={{ backgroundColor: index % 2 === 0 ? theme.palette.background.default : theme.palette.background.paper }}>
                <TableCell><strong>{user.id}</strong></TableCell>
                <TableCell style={{ color: theme.palette.secondary.main }}><strong>{user.username}</strong></TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>{parseFloat(user.balance).toFixed(0)} TRY</TableCell>
                <TableCell>{user.USDT} USDT</TableCell>
                <TableCell>{user.ETH} ETH</TableCell>
                <TableCell>{user.BTC} BTC</TableCell>


                <TableCell align="center">
                  <Button variant="outlined" size="small" color="primary" onClick={() => {
                    setSelectedUser(user);
                    setShowPopup(true);
                  }}>
                    Balance
                  </Button>
                  <Button variant="outlined" size="small" color="secondary" style={{ marginLeft: '10px' }} onClick={() => {
                    setSelectedUser(user);
                    setShowUpdatePopup(true);
                  }}>
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Bakiye Ekleme Popup'u */}
      <Dialog open={showPopup} onClose={() => setShowPopup(false)} maxWidth="xs" fullWidth>
  <DialogTitle>
    <Typography variant="h5" component="div" align="center" gutterBottom>
      Bakiye İşlemi
    </Typography>
  </DialogTitle>
  <DialogContent>
    <Typography variant="body1" align="center" gutterBottom>
      <span style={{ color: red[500], fontWeight: 'bold' }}>
        {selectedUser?.username}
      </span> için işlem türü ve cüzdan seçin:
    </Typography>
    <Box display="flex" justifyContent="center" mb={2}>
      <label>
        <input
          type="radio"
          value="add"
          checked={action === 'add'}
          onChange={() => setAction('add')}
          style={{ marginRight: '5px' }}
        />
        <span style={{ color: green[500] }}>Ekle</span>
      </label>
      <label style={{ marginLeft: '20px' }}>
        <input
          type="radio"
          value="subtract"
          checked={action === 'subtract'}
          onChange={() => setAction('subtract')}
          style={{ marginRight: '5px' }}
        />
        <span style={{ color: red[500] }}>Çıkar</span>
      </label>
    </Box>
    <TextField
      select
      fullWidth
      label="Cüzdan Seç"
      value={currency}
      onChange={(e) => setCurrency(e.target.value)}
      variant="outlined"
      size="small"
      sx={{ mb: 2 }}
    >
      <MenuItem value="TRY">TRY</MenuItem>
      <MenuItem value="USDT">USDT</MenuItem>
      <MenuItem value="ETH">ETH</MenuItem>
      <MenuItem value="BTC">BTC</MenuItem>
    </TextField>
    <TextField
      fullWidth
      type="number"
      value={balance}
      onChange={(e) => setBalance(e.target.value)}
      label="Miktar girin"
      variant="outlined"
      size="small"
      inputProps={{
        style: { textAlign: 'center' },
      }}
    />
  </DialogContent>
  <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
    <Button variant="contained" color="primary" onClick={handleBalanceUpdate} sx={{ width: '45%' }}>
      İşlemi Tamamla
    </Button>
    <Button variant="outlined" onClick={() => setShowPopup(false)} sx={{ width: '45%' }}>
      İptal
    </Button>
  </DialogActions>
</Dialog>


{/* Kullanıcı Güncelleme Popup'u */}
<Dialog open={showUpdatePopup} onClose={() => setShowUpdatePopup(false)} maxWidth="xs" fullWidth>
  <DialogTitle>
    <Typography variant="h6" align="center" component="div" gutterBottom>
      Kullanıcı Bilgilerini Güncelle
    </Typography>
  </DialogTitle>
  <DialogContent>
    <TextField
      fullWidth
      label="Email"
      value={selectedUser?.email}
      onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })}
      variant="outlined"
      size="small"
      sx={{ mb: 2 }}
    />
    <TextField
      fullWidth
      label="Kullanıcı Adı"
      value={selectedUser?.username}
      onChange={(e) => setSelectedUser({ ...selectedUser, username: e.target.value })}
      variant="outlined"
      size="small"
      sx={{ mb: 2 }}
    />
    <TextField
      fullWidth
      label="Yeni Şifre (opsiyonel)"
      type="password"
      value={selectedUser?.password || ''}
      onChange={(e) => setSelectedUser({ ...selectedUser, password: e.target.value })}
      variant="outlined"
      size="small"
      sx={{ mb: 2 }}
    />
    <TextField
      select
      fullWidth
      label="Rol"
      value={selectedUser?.role}
      onChange={(e) => setSelectedUser({ ...selectedUser, role: e.target.value })}
      variant="outlined"
      size="small"
    >
      <MenuItem value="user">User</MenuItem>
      <MenuItem value="admin">Admin</MenuItem>
    </TextField>
  </DialogContent>
  <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
    <Button variant="contained" color="primary" onClick={handleUpdateUser} sx={{ width: '45%' }}>
      Submit
    </Button>
    <Button variant="outlined" onClick={() => setShowUpdatePopup(false)} sx={{ width: '45%' }}>
      İptal
    </Button>
  </DialogActions>
</Dialog>

    </Box>
  );
};

export default Admin;
