import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Login from './pages/Login';
import Payment from './pages/Payment';
import Admin from './pages/Admin';
import Home from './pages/Home';
import Navbar from './components/Navbar';
import { NotificationProvider } from './components/NotificationProvider';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';

const AppContent = ({ user, setUser, mode, toggleMode, handleLogout, isAdmin }) => {
  const location = useLocation(); // Now inside the Router context

  return (
    <>
      {/* Show Navbar only if not on the login page */}
      {location.pathname !== '/login' && <Navbar mode={mode} toggleMode={toggleMode} />}
      <Routes>
        <Route
          path="/"
          element={user ? <Home user={user} /> : <Navigate to="/login" replace />}
        />
        <Route
          path="/login"
          element={<Login setUser={setUser} />}
        />
        <Route
          path="/payment"
          element={user ? <Payment user={user} onLogout={handleLogout} /> : <Navigate to="/login" replace />}
        />
        <Route
          path="/admin"
          element={isAdmin() ? <Admin user={user} /> : <Navigate to="/login" replace />}
        />
      </Routes>
    </>
  );
};

const App = () => {
  const [user, setUser] = useState(undefined);
  const [loading, setLoading] = useState(true);

  // Theme mode (light or dark)
  const [mode, setMode] = useState(localStorage.getItem('themeMode') || 'light');

  // Store the theme mode in localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('themeMode', mode);
  }, [mode]);

  // Toggle between light and dark mode
  const toggleMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  // Define theme
  const theme = createTheme({
    palette: {
      primary: { main: '#008F8C' },
      secondary: { main: '#F29F05' },
      error: { main: '#F23030' },
      warning: { main: '#ff9800' },
      info: { main: '#2196f3' },
      success: { main: '#4caf50' },
      mode: mode,
    },
    typography: { fontFamily: '"Roboto", sans-serif', fontSize: 12 },
  });

  const checkUserSession = () => {
    const loggedInUser = localStorage.getItem('user');
    if (loggedInUser) {
      try {
        const parsedUser = JSON.parse(loggedInUser);
        setUser(parsedUser);
      } catch (error) {
        console.error('Kullanıcı verisi çözülemedi:', error);
        localStorage.removeItem('user');
      }
    }
    setLoading(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    setUser(null);
  };

  useEffect(() => {
    checkUserSession();
  }, []);

  const isAdmin = () => user && user.role === 'admin';

  if (loading) {
    return <div>Yükleniyor...</div>;
  }

  return (
    <NotificationProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <AppContent
            user={user}
            setUser={setUser}
            mode={mode}
            toggleMode={toggleMode}
            handleLogout={handleLogout}
            isAdmin={isAdmin}
          />
        </Router>
      </ThemeProvider>
    </NotificationProvider>
  );
};

export default App;
