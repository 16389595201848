import axios from 'axios';


// Backend API URL'si
const API = axios.create({
  baseURL: 'https://byrex.org/api',  // Backend URL'ini buraya ekleyin
});

API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default API;
